var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserSchema } from '@/shared/validation/user.schema';
import { setScore } from '@/src/utils/api/fbFunctions';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../button/Button';
import Spinner from '../ui/Spinner';
import Input from './Input';
import Label from './Label';
var SetScoreForm = function (_a) {
    var className = _a.className, score = _a.score;
    var _b = useState(null), requestError = _b[0], setRequestError = _b[1];
    var _c = useState(null), requestSuccess = _c[0], setRequestSuccess = _c[1];
    var _d = useForm({
        resolver: zodResolver(UserSchema),
    }), register = _d.register, handleSubmit = _d.handleSubmit, reset = _d.reset, _e = _d.formState, errors = _e.errors, isSubmitting = _e.isSubmitting;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var userData, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userData = __assign(__assign({}, data), { score: score });
                    if (isSubmitting)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, setScore(userData)];
                case 2:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.email) {
                        reset();
                        setRequestError(null);
                        setRequestSuccess('Score set successfully');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (error_1 instanceof Error) {
                        setRequestSuccess(null);
                        setRequestError(error_1.message);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("form", { "data-testid": "signup-form", onSubmit: handleSubmit(onSubmit), className: classNames('flex flex-col gap-4', className), children: [_jsxs(Label, { children: ["Name:", _jsx(Input, { type: "text", name: "name", register: register, placeholder: "Your name", errors: errors })] }), _jsxs(Label, { children: ["Email:", _jsx(Input, { type: "email", name: "email", placeholder: "Your email", register: register, errors: errors })] }), _jsxs(Label, { children: ["Role:", _jsx(Input, { type: "text", name: "role", placeholder: "Your role", register: register, errors: errors })] }), requestError && _jsx("p", { className: "text-gray-200", children: requestError }), requestSuccess && _jsx("p", { className: "text-gray-200", children: requestSuccess }), _jsxs(Button, { "data-testid": "signup-button", disabled: isSubmitting, className: " inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base", children: [isSubmitting && _jsx(Spinner, { color: "white", size: "sm" }), !isSubmitting && 'Set your score'] })] }));
};
export default SetScoreForm;
